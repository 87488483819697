<template>
  <v-chart class="chart" :option="option" autoresize @click="click" v-on:legendselected="legendselected" />
</template>

<script>
import VChart, { THEME_KEY } from 'vue-echarts';
// import { echarts } from "echarts/charts";
import { ref, defineComponent } from 'vue';
import * as echarts from 'echarts';

export default defineComponent({
  name: 'lineChartDuidie',
  props: ['xdata', 'chartData_x', 'isDark', 'chartData_f'],
  components: {
    VChart,
  },
  provide: {
    [THEME_KEY]: 'white',
  },
  watch: {
    xdata(newVal) {
      this.mn()
    },
    chartData_x(newVal) {
      this.mn()
      
    },
    chartData_f(newVal) {
      this.mn()
    },
  },
  mounted() {
    this.mn()

  },
  setup() {
    const option = ref({
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        },
        valueFormatter: (value) => value + '个'
      },
      legend: {
        show: false,
        right: '5%',
        top: '15px'
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },

      series: [],

    });

    return { option };
  },
  methods: {
    getFristData(data){
      let datas = []
      data.forEach(element => {
        datas.push(element[0])
      });
      return datas
    },
    mn() {
      let XData = this.xdata
      let series = []
      let chartData_x = this.chartData_x
      for (let key in chartData_x) {
        series.push({
          name: key,
          type: 'bar',
          stack: 'Ad',
          emphasis: {
            focus: 'series'
          },
          data: chartData_x[key],
        })
      }
      let chartData_f = this.chartData_f
      for (let key in this.chartData_f) {
        series.push({
          name: key,
          type: 'bar',
          stack: 'Ad',
          emphasis: {
            focus: 'series'
          },
          data: chartData_f[key],
        })
      }

      this.option = {
        // color:['#88D66C','#FFA62F','#5AB2FF','#FFF78A','#FF8080','#F266AB','#9E6F21','#FFB100','#ADA2FF'],
        tooltip: {
          trigger: "item",
          axisPointer: {
            type: "shadow"
          },
          formatter(a) {
            let sum = a.seriesName.includes('非项目') ? chartData_f[a.seriesName].reduce((accumulator, currentValue) => accumulator + currentValue.valueValid, 0) : chartData_x[a.seriesName].reduce((accumulator, currentValue) =>  accumulator + currentValue.valueValid, 0)
            let res = '';
            console.log(a)
            res += `${a.seriesName} <br/>`;
            res += `${a.marker} ${a.name} : 确认 ${a.data.value < 0 ? -a.data.value : a.data.valueValid }  <br/>`;
            res += `${a.marker} ${a.name} : 汇报 ${a.data.value < 0 ? -a.data.value : a.data.value }  <br/>`;
            res += `合计 : ${sum < 0 ? -sum : sum} <br/>`;

            return res;
          },
          textStyle: {
            align: 'left'
          }
        },
        legend: {
          show: true,
          top: "0px",
          textStyle: {
            color: this.isDark ? '#fff' : '#000'
          }
        },
        // dataZoom: [{
        //   type: 'inside',
        //   startValue: XData.length - 20
        // }],
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true
        },
        yAxis: {
          type: "value",
          axisLabel: {
            color: this.isDark ? '#fff' : '#000' // 设置X轴坐标轴文字颜色
          },
          splitLine: {
            show: true,
            lineStyle: {
              // 使用深浅的间隔色
              color: 'rgba(255,255,255,0.05)'
            }
          }
        },
        xAxis: {
          type: "category",
          data: XData,
          axisLabel: {
            color: this.isDark ? '#fff' : '#000' // 设置X轴坐标轴文字颜色
          }
        },
        series: series,
        //color: ['#235AFF', '#14C9C9', '#FEB965', '#FE718A']
      }
      console.log(this.option)
    },
    click(e) {
      console.log(e)
    },
    legendselected(e) {
      console.log(e)
    }
  }
});
</script>

<style scoped>
.chart {
  height: 100%;
  /* margin-top: 40px; */
}
</style>
